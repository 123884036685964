const idVideos =
    [
        {
            idVideo : 'Y1OwCYZMUpk',
        },
        {
            idVideo : '4Xd7IMZbZ9A',
        },
        {
            idVideo : 'n0wKSRsRubA',
        }
    ]

export const getIdVideos = () => {
    return idVideos;
}