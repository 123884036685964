import React from 'react'

const Title = () => {
  return (
    <h1 className='titleFooter'>
        ¡Contáctanos!
    </h1>
  )
}

export default Title